exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accedi-js": () => import("./../../../src/pages/accedi.js" /* webpackChunkName: "component---src-pages-accedi-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-beni-culturali-js": () => import("./../../../src/pages/beni-culturali.js" /* webpackChunkName: "component---src-pages-beni-culturali-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-luva-contatti-index-js": () => import("./../../../src/pages/luva/contatti/index.js" /* webpackChunkName: "component---src-pages-luva-contatti-index-js" */),
  "component---src-pages-luva-contatti-spettacoli-js": () => import("./../../../src/pages/luva/contatti/spettacoli.js" /* webpackChunkName: "component---src-pages-luva-contatti-spettacoli-js" */),
  "component---src-pages-luva-eventi-index-js": () => import("./../../../src/pages/luva/eventi/index.js" /* webpackChunkName: "component---src-pages-luva-eventi-index-js" */),
  "component---src-pages-luva-gestione-taverna-index-js": () => import("./../../../src/pages/luva/gestione-taverna/index.js" /* webpackChunkName: "component---src-pages-luva-gestione-taverna-index-js" */),
  "component---src-pages-luva-gestione-taverna-taverna-js": () => import("./../../../src/pages/luva/gestione-taverna/taverna.js" /* webpackChunkName: "component---src-pages-luva-gestione-taverna-taverna-js" */),
  "component---src-pages-luva-index-js": () => import("./../../../src/pages/luva/index.js" /* webpackChunkName: "component---src-pages-luva-index-js" */),
  "component---src-pages-luva-luva-advisor-index-js": () => import("./../../../src/pages/luva/luva-advisor/index.js" /* webpackChunkName: "component---src-pages-luva-luva-advisor-index-js" */),
  "component---src-pages-luva-mercatini-js": () => import("./../../../src/pages/luva/mercatini.js" /* webpackChunkName: "component---src-pages-luva-mercatini-js" */),
  "component---src-pages-luva-notizie-index-js": () => import("./../../../src/pages/luva/notizie/index.js" /* webpackChunkName: "component---src-pages-luva-notizie-index-js" */),
  "component---src-pages-luva-taverne-index-js": () => import("./../../../src/pages/luva/taverne/index.js" /* webpackChunkName: "component---src-pages-luva-taverne-index-js" */),
  "component---src-pages-luva-wine-point-index-js": () => import("./../../../src/pages/luva/wine-point/index.js" /* webpackChunkName: "component---src-pages-luva-wine-point-index-js" */),
  "component---src-pages-notizie-js": () => import("./../../../src/pages/notizie.js" /* webpackChunkName: "component---src-pages-notizie-js" */),
  "component---src-templates-luva-blog-post-js": () => import("./../../../src/templates/luva/blog-post.js" /* webpackChunkName: "component---src-templates-luva-blog-post-js" */),
  "component---src-templates-luva-single-taverna-js": () => import("./../../../src/templates/luva/single-taverna.js" /* webpackChunkName: "component---src-templates-luva-single-taverna-js" */),
  "component---src-templates-visitgattinara-beni-culturali-category-beni-js": () => import("./../../../src/templates/visitgattinara/beni-culturali/category-beni.js" /* webpackChunkName: "component---src-templates-visitgattinara-beni-culturali-category-beni-js" */),
  "component---src-templates-visitgattinara-beni-culturali-single-bene-js": () => import("./../../../src/templates/visitgattinara/beni-culturali/single-bene.js" /* webpackChunkName: "component---src-templates-visitgattinara-beni-culturali-single-bene-js" */),
  "component---src-templates-visitgattinara-single-notizia-js": () => import("./../../../src/templates/visitgattinara/single-notizia.js" /* webpackChunkName: "component---src-templates-visitgattinara-single-notizia-js" */),
  "component---src-templates-visitgattinara-single-scopri-js": () => import("./../../../src/templates/visitgattinara/single-scopri.js" /* webpackChunkName: "component---src-templates-visitgattinara-single-scopri-js" */)
}

